import { createApi } from '@reduxjs/toolkit/query/react';
import { apiBaseQuery, post } from '../helpers';

const workflowApi = createApi({
  reducerPath: 'workflow',
  tagTypes: [],
  baseQuery: apiBaseQuery('/workflow'),
  endpoints: (builder) => ({
    swapConversationCustomer: builder.mutation<void, { conversationId: string; customerId: string }>({
      query: ({ conversationId, customerId }) => post(`/api/conversation/${conversationId}/setData`, [{ Key: 'CustomerId', Value: customerId }]),
    }),
    getWorkflowVariablesForConversation: builder.query<Record<string, string>, { conversationId: string; variableNames: string[] }>({
      query: ({ conversationId, variableNames }) => post(`/conversations/${conversationId}/getData`, variableNames),
    }),
  }),
});

export const {
  useSwapConversationCustomerMutation,
  useGetWorkflowVariablesForConversationQuery,
} = workflowApi;

export default workflowApi;